import React, { useEffect, useState } from "react";
import "../NavBar/NavBar.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import avelogodark from "../../Images/avelogodark.png";
import facebook from "../../Images/fb.png";
import twitter from "../../Images/twitter.png";
import instagram from "../../Images/instagram.png";
import LanguageMenu from "../LanguageMenu/LanguageMenu";
import translations from "../../translations";

const Navbar = ({
  navSupport,
  navAbout,
  navLanguage,
  handleLanguage,
  currentLanguage,
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  useEffect(() => {
    console.log(translations[currentLanguage]);
  }, [currentLanguage]);
  const [toggleMobileLanguage, setToggleMobileLanguage] = useState(false);

  return (
    <header>
      <nav className="nav__menu">
        <div className="nav__logo">
          <a href="/">Ave Digital Museum</a>
        </div>
        <ul className="nav__links">
          <li className="language-wrapper">
            <a href="#">{navLanguage}</a>
            <LanguageMenu
              currentLanguage={currentLanguage}
              handleLanguage={handleLanguage}
            />
          </li>
          <li>
            <a href="#">{navSupport}</a>
          </li>
          <li>
            <a href="#">{navAbout}</a>
          </li>
        </ul>
        <div className="nav__links-smallscreen-menu">
          <AiOutlineMenu
            className="overlay__open"
            fontSize={27}
            onClick={() => setToggleMenu(true)}
            color="#fff"
          />
          {toggleMenu && (
            <>
              <div className="nav__links-smallscreen_overlay">
                <AiOutlineClose
                  fontSize={27}
                  className="overlay__close"
                  onClick={() => setToggleMenu(false)}
                  color="#fff"
                />
                <ul className="nav__links-smallscreen">
                  <li onClick={() => setToggleMobileLanguage(true)}>
                    <a href="#">{navLanguage}</a>
                  </li>
                  <li>
                    <a href="#">{navSupport}</a>
                  </li>
                  <li>
                    <a href="#">{navAbout}</a>
                  </li>
                </ul>

                <div className="nav__links-smallscreen_overlay_social">
                  <a href="#">
                    <img src={facebook} alt="facebook" />
                  </a>
                  <a href="#">
                    <img src={twitter} alt="twitter" />
                  </a>
                  <a href="#">
                    <img src={instagram} alt="instagram" />
                  </a>
                </div>
                <div className="nav__links-smallscreen_overlay_logo">
                  <img src={avelogodark} alt="" />
                  <h6>Ave Team</h6>
                </div>
              </div>
              {toggleMobileLanguage && (
                <div className="nav__links-smallscreen_overlay_language">
                  <AiOutlineClose
                    fontSize={27}
                    className="overlay__close"
                    onClick={() => {
                      setToggleMenu(false);
                      setToggleMobileLanguage(false);
                    }}
                    color="#fff"
                  />
                  <ul className="nav__links-smallscreen">
                    <li
                      onClick={() => {
                        handleLanguage("ua");
                        setToggleMenu(false);
                        setToggleMobileLanguage(false);
                      }}
                    >
                      <a href="#">UA</a>
                    </li>
                    <li
                      onClick={() => {
                        handleLanguage("en");
                        setToggleMenu(false);
                        setToggleMobileLanguage(false);
                      }}
                    >
                      <a href="#">EN</a>
                    </li>
                    <li
                      onClick={() => {
                        handleLanguage("crh");
                        setToggleMenu(false);
                        setToggleMobileLanguage(false);
                      }}
                    >
                      <a href="#">CRH</a>
                    </li>
                  </ul>

                  <div className="nav__links-smallscreen_overlay_social">
                    <a href="#">
                      <img src={facebook} alt="facebook" />
                    </a>
                    <a href="#">
                      <img src={twitter} alt="twitter" />
                    </a>
                    <a href="#">
                      <img src={instagram} alt="instagram" />
                    </a>
                  </div>
                  <div className="nav__links-smallscreen_overlay_logo">
                    <img src={avelogodark} alt="" />
                    <h6>Ave Team</h6>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
