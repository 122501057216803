import React, { useEffect } from "react";
import { useState } from "react";
import "../PlaceComponent/PlaceComponent.css";
import dramtheatre from "../../Images/dramtheatre.png";
import dramtheatresmall from "../../Images/dramtheatresmall.png";
import drammap1 from "../../Images/drammap1.png";
import drammap2 from "../../Images/drammap2.png";
import { AiOutlineClose } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import LightboxModal from "../LightboxModal/LightboxModal";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/css/navigation";

const PlaceComponent = ({ marker, isOpen, onClose, t3dBtn, infoData }) => {
  const [lightboxImage, setLightboxImage] = useState(null);
  const [activeImage, setActiveImage] = useState(null);

  const openLightbox = (image) => {
    setLightboxImage(image);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };
  return (
    <>
      <div className={`place-component ${isOpen ? "open" : ""}`}>
        <div className="place-wrapper-big">
          <div className="close-button">
            <AiOutlineClose
              fontSize={27}
              color="#000"
              onClick={() => {
                onClose();
                const body = document.querySelector("body");
                body.style.backgroundColor = "#fff";
                const allBtn = document.getElementById("allBtn");
                allBtn.style.display = "unset";
              }}
            />
          </div>
          <div className="place-header">
            <h1>{marker.name}</h1>
            <h4>1959-2022</h4>
            <h3>
              Дізнайся більше про міста та села України, що зникли з мапи за
              останні 200 років. Дізнайся більше про міста та села України, що
              зникли з мапи за останні 200 років.{" "}
            </h3>
          { marker.animation == null ? null : <a href="#">{t3dBtn}</a>}
           

          </div>
          <div className="place-bigpic">
            <img
              src={
                "https://museum.aveteam.org/media/" +
                  infoData.images[0]?.file_name || ""
              }
              onClick={() => {
                openLightbox(
                  "https://museum.aveteam.org/media/" +
                    infoData.images[0]?.file_name || ""
                );
                setActiveImage(infoData.images[0]);
              }}
              alt=""
            />
          </div>

          <div className="place-gallery">
            <img
              src={
                "https://museum.aveteam.org/media/" +
                  infoData.images[1]?.file_name || ""
              }
              alt=""
              onClick={() => {
                openLightbox(
                  "https://museum.aveteam.org/media/" +
                    infoData.images[1]?.file_name
                );
                setActiveImage(infoData.images[1]);
              }}
            />
            <img
              src={marker.imageSmall}
              alt=""
              onClick={() => openLightbox(marker.image)}
            />
            <img
              src={marker.imageSmall}
              alt=""
              onClick={() => openLightbox(marker.image)}
            />
          </div>

          <div className="place-gallery-mobile">
            <Swiper
              slidesPerView={"10"}
              centeredSlides={true}
              spaceBetween={30}
              modules={[Pagination]}
              className="mySwiper"
            >
                   {infoData.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`https://museum.aveteam.org/media/${image.file_name}`}
                  onClick={() => {
                    openLightbox(
                      `https://museum.aveteam.org/media/${image.file_name}`
                    );
                    setActiveImage(image);
                  }}
                  alt=""
                />
              </SwiperSlide>
            ))}
            </Swiper>
          </div>
        </div>
        <div className="additional-images">
          <button className="swiper-button-prev"></button>
          <Swiper
            slidesPerView={6}
            centeredSlides={true}
            spaceBetween={30}
            modules={[Pagination, Navigation]}
            className="mySwiper2"
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            {infoData.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`https://museum.aveteam.org/media/${image.file_name}`}
                  onClick={() => {
                    openLightbox(
                      `https://museum.aveteam.org/media/${image.file_name}`
                    );
                    setActiveImage(image);
                  }}
                  alt=""
                />
              </SwiperSlide>
            ))}

            <button className="swiper-button-next"></button>
          </Swiper>
        </div>
        <div className="additional-info">
          <p>{infoData.long_description}</p>
        </div>
        <div className="on-the-maps">
          <div className="place-header">
            <h1>На мапах:</h1>
          </div>
          <div className="map-gallery">
            <img
              src={
                "https://museum.aveteam.org/media/" +
                  infoData.maps[0]?.file_name || ""
              }
              onClick={() =>
                openLightbox(
                  "https://museum.aveteam.org/media/" +
                    infoData.maps[0]?.file_name || ""
                )
              }
              alt=""
            />
            {infoData.maps[1]?.file_name && (
              <img
                src={
                  "https://museum.aveteam.org/media/" +
                    infoData.maps[1]?.file_name || ""
                }
                onClick={() => openLightbox(infoData.maps[1]?.file_name)}
                alt=""
              />
            )}
          </div>
          <div className="map-gallery-mobile">
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={30}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  src={
                    "https://museum.aveteam.org/media/" +
                      infoData.maps[0]?.file_name || ""
                  }
                  onClick={() => {
                    openLightbox(
                      "https://museum.aveteam.org/media/" +
                        infoData.maps[0]?.file_name || ""
                    );
                    setActiveImage(infoData.maps[0]);
                  }}
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {lightboxImage && (
        <LightboxModal
          image={lightboxImage}
          onClose={closeLightbox}
          activeImage={activeImage}
        />
      )}
    </>
  );
};

export default PlaceComponent;
