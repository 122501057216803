import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./LightboxModal.css";
import { BiLinkExternal } from "react-icons/bi";

const LightboxModal = ({ image, onClose, activeImage }) => {
  useEffect(() => {
    const image = document.getElementById("image");

    image.addEventListener("load", () => {
      if (image.naturalHeight > 800) {
        image.style.width = "50%";
      }
    });
    console.log(activeImage);
  }, []);
  return (
    <div className="lightbox-modal">
      <div className="lightbox-content">
        <div className="close-button">
          <AiOutlineClose fontSize={27} color="#000" onClick={onClose} />
        </div>
        <h1>{activeImage.photo_name}</h1>
        <img src={image} alt="" id="image" />

        {activeImage.source_link ? (
          <a href={activeImage.source_link}>
            <h4>
              {activeImage.source} <BiLinkExternal />
            </h4>
          </a>
        ) : (
          <h4>{activeImage.source}</h4>
        )}
      </div>
    </div>
  );
};

export default LightboxModal;
