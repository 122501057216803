import React from "react";
import "./LanguageMenu.css";

const LanguageMenu = ({ handleLanguage, currentLanguage }) => {
  return (
    <div className="language-menu">
      <div className="language-menu__item">
        <button onClick={() => handleLanguage("ua")}>UA</button>
      </div>
      <div className="language-menu__item">
        <button onClick={() => handleLanguage("en")}>EN</button>
      </div>
      <div className="language-menu__item">
        <button onClick={() => handleLanguage("crh")}>CRH</button>
      </div>
    </div>
  );
};

export default LanguageMenu;
