import logoUA from "./Images/logo.png";
import logoEN from "./Images/logoEN.png";
import logoCRH from "./Images/logoCRH.png";

const translations = {
  en: {
    navLanguage: "Language",
    navSupport: "Support",
    navAbout: "About",
    heroBtnText: "Go to the map",
    heroText:
      "Discover more about the cities and villages of Ukraine that disappeared from the map over the last 200 years.",
    heroImage: logoEN,
    moreBtn: "More",
    t3dBtn: "Watch the lecture in 3D",
  },
  ua: {
    navLanguage: "Мова",
    navSupport: "Підтримати",
    navAbout: "Про проєкт",
    heroBtnText: "Перейти до мапи",
    heroText:
      "Дізнайся більше про міста та села України, що зникли з мапи за останні 200 років.",
    heroImage: logoUA,
    moreBtn: "Детальніше",
    t3dBtn: "Переглянути лекцію в 3D",
  },
  crh: {
    navLanguage: "Til",
    navSupport: "Desteklemek",
    navAbout: "Leyha aqqında",
    heroBtnText: "Haritağa keçmek",
    heroText:
      "200 yıl boyunda yoq etilgen Ukrainanıñ şeerleri ve binaları aqqında ziyadece haber al.",
    heroImage: logoUA,
    moreBtn: "Tafsilât",
    t3dBtn: "3D şekilde lektsiyanı baqmaq",
  },
};

export default translations;
