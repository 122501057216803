import React, { useEffect } from "react";
import dramtheatremedium from "../../Images/dramtheatremedium.png";
import { AiOutlineClose } from "react-icons/ai";
import "./Info.css";
import axios, { all } from "axios";
import { useState } from "react";

const Info = ({
  infoData,
  isOpen,
  marker,
  onClose,
  onOpenPlace,
  moreBtn,
  t3dBtn,
  setInfoData,
}) => {
  const fetchData = async (marker) => {
    try {
      const data = `{"request_type": "info", "location_id": ${marker.id} }`;
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://museum.aveteam.org/API/getData.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAdwblwK6DNGUXFF2AgI45Av/veHgje14bWRiatNG/RoiIax5kcQeuP8Bek77+2poLhAc9E5/V13FL1QmWbVf5fQIDAQAB", // Replace with your actual token
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(response);
      setInfoData(response.data);
      console.log(infoData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (marker) {
      fetchData(marker);
    } else {
      console.log("marker is null");
    }
  }, []);

  const moreInfo = () => {
    /* set body bg to #d8dce1 */
    const body = document.querySelector("body");
    body.style.backgroundColor = "#d8dce1";
    /* get button with id allBtn */
    const allBtn = document.getElementById("allBtn");
    allBtn.style.display = "none";
    fetchData(marker);
    onClose();
    onOpenPlace(true);
  };

  return (
    <>
      <div className={`small-info-wrapper ${isOpen ? "open" : ""}`}>
        <div className="place-wrapper">
          <div className="close-button">
            <AiOutlineClose
              fontSize={27}
              color="#000"
              onClick={() => onClose()}
            />
          </div>
          <div className="place-header">
            <h1>{marker.name}</h1>
            <h4>
              {marker.build_date}-{marker.destruction_date}
            </h4>
          </div>
          <div className="place-gallery">
            <img
              src={
                "https://museum.aveteam.org/media/" +
                  infoData.images[0]?.file_name || ""
              }
              alt=""
            />
          </div>
          <h3>
            Дізнайся більше про міста та села України, що зникли з мапи за
            останні 200 років. Дізнайся більше про міста та села України, що
            зникли з мапи за останні 200 років.{" "}
          </h3>
          <div className="small-info-buttons">
            <a onClick={moreInfo}>{moreBtn}</a>
            
            { marker.animation == null ? null : <a href="#">3D</a>}

          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
