import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import "./PlacesList.css";
import arIcon from "../../Images/arIcon.png";
import locationIcon from "../../Images/locationIcon.png";
import { IoMdAdd } from "react-icons/io";
import { BsFillHouseAddFill } from "react-icons/bs";

const PlacesList = ({
  isOpen,
  onClose,
  onOpenPlace,
  setSelectedMarker,
  map,
  setInfoData,
}) => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = '{"request_type": "list"}';
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://museum.aveteam.org/API/getData.php",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAdwblwK6DNGUXFF2AgI45Av/veHgje14bWRiatNG/RoiIax5kcQeuP8Bek77+2poLhAc9E5/V13FL1QmWbVf5fQIDAQAB", // Replace with your actual token
          },
          data: data,
        };

        const response = await axios.request(config);
        setApiData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData2 = async () => {
      try {
        const data = '{"request_type": "list"}';
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://aveteam.org/APIs/test/museum/getData.php",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAdwblwK6DNGUXFF2AgI45Av/veHgje14bWRiatNG/RoiIax5kcQeuP8Bek77+2poLhAc9E5/V13FL1QmWbVf5fQIDAQAB", // Replace with your actual token
          },
          data: data,
        };

        const response = await axios.request(config);

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchData2();
  }, []);

  const fetchData3 = async (marker) => {
    try {
      const data = `{"request_type": "info", "location_id": ${marker.id} }`;
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://museum.aveteam.org/API/getData.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAdwblwK6DNGUXFF2AgI45Av/veHgje14bWRiatNG/RoiIax5kcQeuP8Bek77+2poLhAc9E5/V13FL1QmWbVf5fQIDAQAB", // Replace with your actual token
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response);
      setInfoData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openPlace = (marker) => () => {
    const zoomInButton = document.getElementById("ZoomInButton");
    const zoomOutButton = document.getElementById("ZoomOutButton");
    const allBtn = document.getElementById("allBtn");
    zoomInButton.classList.remove("open-list");
    zoomOutButton.classList.remove("open-list");
    allBtn.classList.remove("open-list");
    onClose();
    setSelectedMarker(marker);
    fetchData3(marker);

    console.log(map);
    const targetZoom = 15;
    const animationDuration = 200;
    const totalSteps = 1;
    const initialZoom = map.getZoom();
    const zoomStep = (targetZoom - initialZoom) / totalSteps;
    const targetLocation = new window.Microsoft.Maps.Location(
      marker.latitude,
      marker.longitude
    );

    for (let i = 1; i <= totalSteps; i++) {
      setTimeout(() => {
        const currentZoom = initialZoom + zoomStep * i;
        map.setView({
          center: targetLocation,
          zoom: currentZoom,
        });
      }, (animationDuration / totalSteps) * i);
    }

    setTimeout(() => {
      onOpenPlace(true);
    }, animationDuration);
  };

  return (
    <>
      <div className={`small-info-wrapper-list ${isOpen ? "open" : ""}`}>
        <div className="place-wrapper">
          <div className="close-button">
            <AiOutlineClose fontSize={27} color="#000" onClick={onClose} />
          </div>
          <div className="places-list">
            <div className="places-list-header">
              <h2>Донеччина</h2>
              <h2>{apiData.length}</h2>
            </div>
            {apiData.map((marker) => (
              <div className="small-place" key={marker.id}>
                <div className="small-place-name">
                  <h3>{marker.name}</h3>
                </div>
                <div className="small-place-icons">
                  <a href="#">
                    <img src={arIcon} alt="" />
                  </a>
                  <a href="#" onClick={openPlace(marker)}>
                    <img src={locationIcon} alt="" />
                  </a>
                </div>
              </div>
            ))}
            <div className="small-place-line"></div>
            <div className="add-place-block">
              <a
                href="https://forms.gle/54ARB5G7qsY855in7"
                className="add-place-btn"
              >
                <IoMdAdd className="icon-default" fontSize={30} />
                <BsFillHouseAddFill className="icon-hover" fontSize={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacesList;
