import React from "react";
import Navbar from "../Components/NavBar/NavBar";
import MapComponent from "../Components/Map/MapComponent";
import Info from "../Components/Info/Info";
import Footer from "../Components/Footer/Footer";

const Map = ({ handleLanguage, currentLanguage, translations }) => {
  return (
    <>
      <Navbar
        currentLanguage={currentLanguage}
        handleLanguage={handleLanguage}
        navLanguage={translations[currentLanguage].navLanguage}
        navSupport={translations[currentLanguage].navSupport}
        navAbout={translations[currentLanguage].navAbout}
      />
      <MapComponent
        currentLanguage={currentLanguage}
        handleLanguage={handleLanguage}
        moreBtn={translations[currentLanguage].moreBtn}
        t3dBtn={translations[currentLanguage].t3dBtn}
      />
      <Footer />
    </>
  );
};

export default Map;
