import React from "react";
import logo from "../../Images/logo.png";
import "./HeroSection.css";
import partnersLogo from "../../Images/partnersLogo.png";

const HeroSection = ({ heroText, heroImage, heroBtnText }) => {
  return (
    <>
      <section className="hero__section">
        <div className="hero__section__content">
          <a href="/map">
            <img src={heroImage} alt="Logo" />
          </a>

          <h2>{heroText}</h2>
          <div className="hero__section__btn">
            <a href="/map" className="hero__section__btn">
              {heroBtnText}
            </a>
          </div>
        </div>
      </section>
      <div className="made__with__block">
        <h1>Проєкт створено разом з:</h1>
        <img src={partnersLogo} alt="partners" />
      </div>
    </>
  );
};

export default HeroSection;
