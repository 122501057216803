import React from "react";
import "./Footer.css";
import avelogo from "../../Images/ave-logo.png";

const Footer = () => {
  return (
    <footer>
      <img src={avelogo} alt="" />
      <h6>Ave Team</h6>
    </footer>
  );
};

export default Footer;
