import React, { useEffect, useState } from "react";
import maplogo from "../../Images/map-logo.png";
import MarkerPopup from "../MarkerPopup/MarkerPopup";
import "./MapComponent.css";
import Info from "../Info/Info";
import PlaceComponent from "../PlaceComponent/PlaceComponent";
import axios from "axios";
import PlacesList from "../PlacesList/PlacesList";
import locationIcon from "../../Images/locationIcon.svg";
import locationIconUA from "../../Images/locationIconUA.svg";
import locationIconCRH from "../../Images/locationIconCRH.svg";
import locationIconBLR from "../../Images/locationIconBLR.svg";
import locationIconBLRTAT from "../../Images/locationIconBLRTAT.svg";
import locationIconKDR from "../../Images/locationIconKDR.svg";
import locationIconJCT from "../../Images/locationIconJCT.svg";

function MapComponent({ currentLanguage, handleLanguage, moreBtn, t3dBtn }) {
  const [selectedMarker, setSelectedMarker] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPlaceOpen, setIsPlaceOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [map, setMap] = useState(null);
  const [infoData, setInfoData] = useState({
    type: "",
    short_description: "",
    long_description: "",
    build_date: 0,
    destruction_date: 0,
    region: "",
    animation: null,
    images: [
      {
        id: 0,
        photo_name: "",
        file_name: "",
        source: "",
        source_link: null,
      },
    ],
    maps: [
      {
        id: 0,
        map_name: "",
        file_name: "",
        source: "",
        source_link: null,
      },
    ],
    view_count_last_30_days: 0,
  });

  const [markers, setMarkers] = useState([]);

  const fetchData = async (marker) => {
    try {
      const data = `{"request_type": "info", "location_id": ${marker.id} }`;
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://museum.aveteam.org/API/getData.php",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAdwblwK6DNGUXFF2AgI45Av/veHgje14bWRiatNG/RoiIax5kcQeuP8Bek77+2poLhAc9E5/V13FL1QmWbVf5fQIDAQAB", // Replace with your actual token
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response);
      setInfoData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = '{"request_type": "list"}';
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://museum.aveteam.org/API/getData.php",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAdwblwK6DNGUXFF2AgI45Av/veHgje14bWRiatNG/RoiIax5kcQeuP8Bek77+2poLhAc9E5/V13FL1QmWbVf5fQIDAQAB", // Replace with your actual token
          },
          data: data,
        };

        const response = await axios.request(config);
        setMarkers(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.bing.com/api/maps/mapcontrol?key=AhdtkFvTJpP-cUfA62tWx9eOGNaLL7rFEy3zkjj2goPjqghTx9e6lpVAFocU-r_T&callback=initMap";
    script.async = true;
    script.defer = true;

    // Add error event listener to handle script loading errors
    script.addEventListener("error", handleError);

    document.body.appendChild(script);

    // Initialize the map
    // Initialize the map
    window.initMap = function () {
      const map = new window.Microsoft.Maps.Map(
        document.getElementById("myMap"),
        {
          zoom: 6,
          center: new window.Microsoft.Maps.Location(48.0275, 31.4828), // Set the coordinates for the center of the map
          //remove map type selector
          showMapTypeSelector: false,
          // add zoom
          showZoomButtons: true,
          minZoom: 6,
          // remove ability to move map
          liteMode: true,
        }
      );
      setMap(map);

      // Generate your Plotly markers
    };

    // Cleanup function
    return () => {
      script.removeEventListener("error", handleError);
      document.body.removeChild(script);
      delete window.initMap;
    };
  }, []);

  useEffect(() => {
    if (map) {
      // Convert Plotly markers to Bing Maps pushpins
      const pushpins = markers.map((marker) => {
        const pushpinOptions = {
          color: "black",
          width: marker.size,
          height: marker.size,
          icon: determineIcon(marker.nation),
        };

        const pushpin = new window.Microsoft.Maps.Pushpin(
          new window.Microsoft.Maps.Location(marker.latitude, marker.longitude),
          pushpinOptions
        );

        // Add onClick event listener to the pushpin
        window.Microsoft.Maps.Events.addHandler(pushpin, "click", function () {
          setSelectedMarker(marker);
          fetchData(marker);
          setIsPopupOpen(true);
        });

        return pushpin;
      });

      // Create a ClusterLayer with options and add it to the map
      window.Microsoft.Maps.loadModule(
        "Microsoft.Maps.Clustering",
        function () {
          const clusterLayer = new window.Microsoft.Maps.ClusterLayer(
            pushpins,
            {
              clusteredPinCallback: createCustomClusteredPin,
              gridSize: 80,
            }
          );
          map.layers.insert(clusterLayer);
        }
      );
    }
  }, [map]);
  function determineIcon(nation) {
    if (nation === "ua") {
      return locationIconUA;
    } else if (nation === "crh") {
      return locationIconCRH;
    } else if (nation === "blr") {
      return locationIconBLR;
    } else if (nation === "blrtat") {
      return locationIconBLRTAT;
    } else if (nation === "kdr") {
      return locationIconKDR;
    } else if (nation === "jct") {
      return locationIconJCT;
    } else {
      return locationIcon; // Default icon
    }
  }
  // Custom cluster pin callback function
  function createCustomClusteredPin(cluster) {
    // Define variables for minimum cluster radius and outline area width
    const minRadius = 30;
    const outlineWidth = 7;

    // Get the number of pushpins in the cluster
    const clusterSize = cluster.containedPushpins.length;

    // Calculate the radius of the cluster based on the number of pushpins
    const radius = (Math.log(clusterSize) / Math.log(10)) * 5 + minRadius;

    // Customize the cluster pin based on the number of pushpins
    let fillColor = "#000000"; // Default red color
    if (clusterSize < 10) {
      fillColor = "#000000"; // Green color for fewer than 10 pushpins
    } else if (clusterSize < 100) {
      fillColor = "#000000"; // Yellow color for 10 to 99 pushpins
    }

    /* set font size 36 px */
    const fontSize = 36;

    // Create an SVG string of two circles, one on top of the other
    // Customize the clustered pushpin using the generated SVG
    const svg = `<svg style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="${
      radius * 2
    }" height="${radius * 2}">
  <circle cx="${radius}" cy="${radius}" r="${radius}" fill="${fillColor}"/>
  <circle cx="${radius}" cy="${radius}" r="${
      radius - outlineWidth
    }" fill="${fillColor}"/>
  <text x="${radius}" y="${radius}" text-anchor="middle" dominant-baseline="central" font-size="${fontSize}px" fill="#ffffff" font-family="e-Ukraine Head">
    ${clusterSize}
  </text>
</svg>`;

    // Customize the clustered pushpin using the generated SVG

    cluster.setOptions({
      icon: svg,
      anchor: new window.Microsoft.Maps.Point(radius, radius),
      textOffset: new window.Microsoft.Maps.Point(0, radius - 8),
      text: "",
    });
    // Customize the clustered pushpin using the generated SVG
    const pushpinOptions = {
      icon: svg,
      anchor: new window.Microsoft.Maps.Point(radius, radius),
      textOffset: new window.Microsoft.Maps.Point(0, radius - 8),
      fontSize: fontSize,
    };

    const clusteredPushpin = new window.Microsoft.Maps.Pushpin(
      cluster.getLocation(),
      pushpinOptions
    );

    window.Microsoft.Maps.Events.addHandler(cluster, "click", function () {
      // Get the number of pushpins in the cluster
      const markerCount = cluster.containedPushpins.length;
      console.log(`Clustered marker clicked! Contains ${markerCount} markers.`);

      // Open the sub-clusters
      cluster.containedPushpins.forEach((pushpin) => {
        pushpin.setOptions({ visible: true });
      });
      console.log(map);
      console.log(cluster.getLocation());
      const targetLocation = new window.Microsoft.Maps.Location(
        cluster.getLocation().latitude,
        cluster.getLocation().longitude
      );
      console.log(targetLocation);
      map.setView({
        center: targetLocation,
        zoom: map.getZoom() + 3.5,
      });
    });

    return clusteredPushpin;
  }

  // Error event handler
  function handleError(event) {
    console.error("Error loading Bing Maps script.");
    // You can choose to handle the error gracefully or ignore it.
    // For example, you could display a message to the user or take any necessary actions.
  }

  const openList = () => {
    setIsListOpen(true);
    const zoomInButton = document.getElementById("ZoomInButton");
    const zoomOutButton = document.getElementById("ZoomOutButton");
    const allBtn = document.getElementById("allBtn");
    zoomInButton.classList.add("open-list");
    zoomOutButton.classList.add("open-list");
    allBtn.classList.add("open-list-all");
  };
  return (
    <>
      <Info
        moreBtn={moreBtn}
        t3dBtn={t3dBtn}
        marker={selectedMarker}
        isOpen={isPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          const zoomInButton = document.getElementById("ZoomInButton");
          const zoomOutButton = document.getElementById("ZoomOutButton");
          const allBtn = document.getElementById("allBtn");
          zoomInButton.classList.remove("open-list");
          zoomOutButton.classList.remove("open-list");
          allBtn.classList.remove("open-list-all");
        }}
        onOpenPlace={() => {
          setIsPlaceOpen(true);
        }}
        setInfoData={setInfoData}
        infoData={infoData}
      />
      <PlaceComponent
        marker={selectedMarker}
        isOpen={isPlaceOpen}
        moreBtn={moreBtn}
        t3dBtn={t3dBtn}
        onClose={() => {
          setIsPlaceOpen(false);
        }}
        infoData={infoData}
      />
      <PlacesList
        isOpen={isListOpen}
        onClose={() => {
          setIsListOpen(false);
          const zoomInButton = document.getElementById("ZoomInButton");
          const zoomOutButton = document.getElementById("ZoomOutButton");
          const allBtn = document.getElementById("allBtn");
          zoomInButton.classList.remove("open-list");
          zoomOutButton.classList.remove("open-list");
          allBtn.classList.remove("open-list-all");
        }}
        onOpenPlace={() => {
          setIsPopupOpen(true);
          const zoomInButton = document.getElementById("ZoomInButton");
          const zoomOutButton = document.getElementById("ZoomOutButton");
          const allBtn = document.getElementById("allBtn");
          zoomInButton.classList.add("open-list");
          zoomOutButton.classList.add("open-list");
          allBtn.classList.add("open-list-all");
        }}
        setInfoData={setInfoData}
        setSelectedMarker={setSelectedMarker}
        map={map}
      />
      <div className="map-header">
        <img src={maplogo} alt="" />
      </div>

      <div
        id="myMap"
        style={{ width: "100%", height: "82vh", fontFamily: "e-Ukraine Head" }}
      ></div>
      <a href="#" id="allBtn" onClick={openList}></a>
    </>
  );
}

export default MapComponent;
