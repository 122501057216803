import React from "react";
import Navbar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";
import PlaceComponent from "../Components/PlaceComponent/PlaceComponent";

const Place = () => {
  return (
    <>
      <Navbar navSupport="Підтримати" navAbout="Про проєкт" />
      <PlaceComponent />
    </>
  );
};

export default Place;
