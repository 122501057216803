import React, { useState } from "react";
import Navbar from "../Components/NavBar/NavBar";
import HeroSection from "../Components/HeroSection/HeroSection";
import Footer from "../Components/Footer/Footer";
import translations from "../translations";

const Home = ({ handleLanguage, currentLanguage, translations }) => {
  return (
    <>
      <Navbar
        currentLanguage={currentLanguage}
        handleLanguage={handleLanguage}
        navLanguage={translations[currentLanguage].navLanguage}
        navSupport={translations[currentLanguage].navSupport}
        navAbout={translations[currentLanguage].navAbout}
      />
      <HeroSection
        heroText={translations[currentLanguage].heroText}
        heroImage={translations[currentLanguage].heroImage}
        heroBtnText={translations[currentLanguage].heroBtnText}
      />
      <Footer />
    </>
  );
};

export default Home;
