import Home from "./Pages/Home";
import Map from "./Pages/Map";
import "../src/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Place from "./Pages/Place";
import translations from "./translations";
import { useEffect, useState } from "react";

function App() {
  const [currentLanguage, setCurrentLanguage] = useState("ua");
  const handleLanguageChange = (lang) => {
    setCurrentLanguage(lang);
    localStorage.setItem("language", lang);
    console.log(currentLanguage);
  };
  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      setCurrentLanguage(lang);
    } else {
      setCurrentLanguage("ua");
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                currentLanguage={currentLanguage}
                handleLanguage={handleLanguageChange}
                translations={translations}
              />
            }
          />
          <Route
            path="/map"
            element={
              <Map
                currentLanguage={currentLanguage}
                handleLanguage={handleLanguageChange}
                translations={translations}
              />
            }
          />
          <Route path="/place" element={<Place />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
